<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        DOPPLER OBSTÉTRICO COM PERFIL CERVICAL
      </h2>
    </v-card>
    <v-alert
      v-if="!!laudoSelecionado.laudoJson.folhas"
      outlined
      type="warning"
      border="left"
      text=""
    >
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado"
        >Clique aqui para visualizar</v-btn
      >
    </v-alert>

    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col
          cols="12"
          class="form-group"
          v-for="(index, folha) in quantidadeFolhasEditorPersonalizado"
          :key="index"
        >
          <h2>Folha {{ folha + 1 }}</h2>
          <v-btn plain small @click="removeFolha(index)" class="pl-0">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>
      <template v-else>
        <v-col cols="12" class="d-flex justify-start align-center">
          <span>
            Idade gestacional baseado na biometria fetal atual de
          </span>
          <v-text-field v-mask="'##'" required class="mx-2 flex-grow-0" dense validate-on-blur v-model="laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
            " :readonly="carregamentoDoLaudo || !podeEditar">
          </v-text-field>
          <span>semanas. </span>
          <!-- <v-btn dense depressed text icon v-if="!camposExibidos.exibeIdadeSemanas" @click="
            camposExibidos.exibeIdadeSemanas = true;
          laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas =
            undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeIdadeSemanas = false;
          laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas =
            undefined;
          "><v-icon>mdi-eye</v-icon></v-btn> -->
          <span class="text--disabled ">&nbsp; O campo de idade não é visível na impressão. </span>
        </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Artéria Umbilical:</h3>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical
          ? ''
          : 'text--disabled'
          }`">
          <span>Índice de Pulsatilidade:</span>
          <v-text-field v-mask="''" class="mx-2 flex-grow-0" dense required validate-on-blur
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical" @change="getIndicePulsatilidade"
            :readonly="carregamentoDoLaudo || !podeEditar" v-model="laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical
              "></v-text-field>
          <span>- Percentil</span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical" class="mx-2 flex-grow-0" dense
            v-mask="''" v-model="laudoSelecionado.laudoJson.percentil"></v-text-field>
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical" :items="['normal', 'alterado']"
            v-model="laudoSelecionado.laudoJson.indiceOpcao3" :clearable="podeEditar" class="mx-2 flex-grow-0"
            dense></v-select>
          <span>para idade gestacional.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical" @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical = true;
          laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical =
            undefined;
          laudoSelecionado.laudoJson.percentil = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical = false;
          laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical =
            undefined;
          laudoSelecionado.laudoJson.percentil = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeDiastole ? '' : 'text--disabled'
          }`">
          <span>Diástole</span>
          <v-select :clearable="podeEditar" class="mx-2 flex-grow-0" dense :disabled="!camposExibidos.exibeDiastole"
            :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur
            :items="['presente', 'ausente', 'reversa']" v-model="laudoSelecionado.laudoJson.diastole"></v-select>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeDiastole" @click="
            camposExibidos.exibeDiastole = true;
          laudoSelecionado.laudoJson.diastole = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeDiastole = false;
          laudoSelecionado.laudoJson.diastole = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Artéria cerebral média:</h3>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia
          ? ''
          : 'text--disabled'
          }`">
          <span>Índice de Pulsatilidade:</span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" @change="getACM" v-model="laudoSelecionado.laudoJson.indicePulsatilidadeArteriaCerebralMedia
            " required :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia
    " class="mx-2 flex-grow-0" dense validate-on-blur v-mask="''"></v-text-field>
          <span>- Percentil</span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" v-model="laudoSelecionado.laudoJson.percentil3"
            required :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia
              " validate-on-blur v-mask="''" class="mx-2 flex-grow-0" dense></v-text-field>
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia
            " :items="['normal', 'alterado']" v-model="laudoSelecionado.laudoJson.indiceOpcao1" :clearable="podeEditar"
            class="mx-2 flex-grow-0" dense></v-select>
          <span>para idade gestacional.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia" @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia = true;
          laudoSelecionado.laudoJson.indicePulsatilidadeArteriaCerebralMedia =
            undefined;
          laudoSelecionado.laudoJson.indiceOpcao1 = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia = false;
          laudoSelecionado.laudoJson.indicePulsatilidadeArteriaCerebralMedia =
            undefined;
          laudoSelecionado.laudoJson.indiceOpcao1 = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla
          ? ''
          : 'text--disabled'
          }`">
          <span>Relação cérebro-placentária:</span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" v-model="laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla
            " required :disabled="!camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla
    " validate-on-blur class="mx-2 flex-grow-0" dense v-mask="''"></v-text-field>
          <span>- Percentil</span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" v-model="laudoSelecionado.laudoJson.percentil2"
            required :disabled="!camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla
              " validate-on-blur v-mask="''" class="mx-2 flex-grow-0" dense></v-text-field>
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" required class="mx-2 flex-grow-0" dense :disabled="!camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla
            " validate-on-blur :items="['normal', 'alterado']"
            v-model.number="laudoSelecionado.laudoJson.indiceOpcaoCerePlac" :clearable="podeEditar"></v-select>
          <span>para idade gestacional.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla" @click="
            camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla = true;
          laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla =
            undefined;
          laudoSelecionado.laudoJson.percentil2 = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeIndicePulsatilidadeRelacaoCerebroPla = false;
          laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla =
            undefined;
          laudoSelecionado.laudoJson.percentil2 = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeVelocidadePicoSistolico ? '' : 'text--disabled'
          }`">
          <span>Velocidade de Pico Sistólica</span>

          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.opcaoVelocidade" required
            :disabled="!camposExibidos.exibeVelocidadePicoSistolico" validate-on-blur class="mx-2 flex-grow-0" dense
            v-mask="''"></v-text-field>
          <span required :disabled="!camposExibidos.exibeVelocidadePicoSistolico" validate-on-blur>
            cm/s, correspondendo a
          </span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.opcaoVelocidade2" required validate-on-blur class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeVelocidadePicoSistolico" v-mask="''"></v-text-field>
          <span> múltiplos da mediana. </span>

          <!-- <v-select

          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeVelocidadePicoSistolico"
          :items="[
            ',sem sinais de anemia',
            ',sugerindo anemia leve',
            ',sugerindo anemia moderada',
            ',sugerindo anemia grave',
          ]"
          v-model="laudoSelecionado.laudoJson.opcaoVelocidade3"
          :clearable="podeEditar"
        ></v-select> -->
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeVelocidadePicoSistolico" @click="
            camposExibidos.exibeVelocidadePicoSistolico = true;
          laudoSelecionado.laudoJson.velocidadePicoSistolico = undefined;
          laudoSelecionado.laudoJson.opcaoVelocidade = undefined;
          laudoSelecionado.laudoJson.opcaoVelocidade2 = undefined;
          laudoSelecionado.laudoJson.opcaoVelocidade3 = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeVelocidadePicoSistolico = false;
          laudoSelecionado.laudoJson.velocidadePicoSistolico = undefined;
          laudoSelecionado.laudoJson.opcaoVelocidade = undefined;
          laudoSelecionado.laudoJson.opcaoVelocidade2 = undefined;
          laudoSelecionado.laudoJson.opcaoVelocidade3 = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Artéria Uterina:</h3>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD
          ? ''
          : 'text--disabled'
          }`">
          <span>Índice de Pulsatilidade (direita):</span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" v-model="laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD
            " required class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD" validate-on-blur
            v-mask="''"></v-text-field>
          <!-- <span>- Percentil</span>
              <v-text-field
                :readonly="carregamentoDoLaudo || !podeEditar"
                required
                validate-on-blur
                :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD"
                class="mx-2 flex-grow-0"
                dense
                v-mask="''"
                v-model="laudoSelecionado.laudoJson.percentil2160"
              ></v-text-field>

              <v-select
                :readonly="carregamentoDoLaudo || !podeEditar"
                required
                validate-on-blur
                :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD"
                :items="['normal', 'alterado']"
                v-model="laudoSelecionado.laudoJson.indiceOpcao2"
                :clearable="podeEditar"
                class="mx-2 flex-grow-0"
                dense
              ></v-select>
              <span>para idade gestacional.</span> -->
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD" @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD = true;
          laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD =
            undefined;
          laudoSelecionado.laudoJson.indiceOpcao2 = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD = false;
          laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD =
            undefined;
          laudoSelecionado.laudoJson.indiceOpcao2 = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <!-- <v-col cols="12" class="d-flex justify-start align-center">
              <h3>Artéria Uterina esquerda:</h3>
            </v-col> -->

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE
          ? ''
          : 'text--disabled'
          }`">
          <span>Índice de Pulsatilidade (esquerda):</span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" v-model="laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
            " required class="mx-2 flex-grow-0" :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE"
            dense validate-on-blur v-mask="''"></v-text-field>

          <v-btn dense depressed text icon v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE" @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE = true;
          laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE =
            undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE = false;
          laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE =
            undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2
          ? ''
          : 'text--disabled'
          }`">
          <span>Índice de Pulsatilidade médio: </span>
          <span cols="12" :class="`pl-2 pr-2 d-flex justify-start align-center ${camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2
            ? ''
            : 'text--disabled'
            } `">
            {{ mediaIndice }}
          </span>
          <span> - Percentil</span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2" class="mx-2 flex-grow-0" dense
            v-mask="''" v-model="laudoSelecionado.laudoJson.percentilArtMed"></v-text-field>

          <v-select :readonly="carregamentoDoLaudo || !podeEditar" required class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2" validate-on-blur
            :items="['normal', 'alterado']" v-model.number="laudoSelecionado.laudoJson.indiceOpcaoArtMed"
            :clearable="podeEditar"></v-select>
          <span>para idade gestacional.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2" @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2 = true
            "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE2 = false;
          laudoSelecionado.laudoJson.percentilArtMed = undefined;
          laudoSelecionado.laudoJson.indiceOpcaoArtMed = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <!-- <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDuctoVenoso ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeDuctoVenoso"
            :items="['Ducto Venoso', '----------------------------------']"
            v-model="laudoSelecionado.laudoJson.ductoVenoso"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>

          <span v-if="laudoSelecionado.laudoJson.ductoVenoso == 'Ducto Venoso'"
            >índice de Pulsatilidade:</span
          >

          <v-text-field
            v-if="laudoSelecionado.laudoJson.ductoVenoso == 'Ducto Venoso'"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso"
            required
            :disabled="!camposExibidos.exibeDuctoVenoso"
            :clearable="podeEditar"
            validate-on-blur
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
          ></v-text-field>

          <v-select
            v-if="laudoSelecionado.laudoJson.ductoVenoso == 'Ducto Venoso'"
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            :disabled="!camposExibidos.exibeDuctoVenoso"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            :items="['normal', 'alterado']"
            v-model.number="laudoSelecionado.laudoJson.indiceOpcao4"
            :clearable="podeEditar"
          ></v-select>
          <span v-if="laudoSelecionado.laudoJson.ductoVenoso == 'Ducto Venoso'"
            >para idade gestacional.</span
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeDuctoVenoso"
            @click="
              camposExibidos.exibeDuctoVenoso = true;
              laudoSelecionado.laudoJson.ductoVenoso = undefined;
              laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso =
                undefined;
              laudoSelecionado.laudoJson.indiceOpcao4 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeDuctoVenoso = false;
              laudoSelecionado.laudoJson.ductoVenoso = undefined;
              laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso =
                undefined;
              laudoSelecionado.laudoJson.indiceOpcao4 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col> -->

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>

        <div class="pt-5">
          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkFluxoUmb"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Fluxo umbilical normal para a idade gestacional."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkAusFet"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Ausência de centralização fetal."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkRelCerNormal"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Relação cerebro-placentária normal para a idade gestacional."
            validate-on-blur
          ></v-checkbox>
          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkFluxoUmbRes"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Fluxo umbilical com resistência elevada."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkRelCerAcima"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Relação cerebro-placentária acima do percentil esperado para a idade gestacional. Convém monitorar."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkCentraFet"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Centralização fetal."
            validate-on-blur
          ></v-checkbox>
          <div class="d-flex justify-content-start align-items-center mt-n5">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.checkDucto"
              :readonly="carregamentoDoLaudo || !podeEditar"
              label="Ducto venoso "
              validate-on-blur
            ></v-checkbox>
            &nbsp;&nbsp;
            <v-select
              :readonly="carregamentoDoLaudo || !podeEditar"
              required
              validate-on-blur
              :disabled="!laudoSelecionado.laudoJson.checkDucto"
              class="d-flex justify-content-start align-self-center mx-2 flex-grow-0"
              dense
              :items="['normal', 'alterado']"
              v-model.number="laudoSelecionado.laudoJson.ductoVenNormAlt"
              :clearable="podeEditar"
            ></v-select>
          </div>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkIndiceAcima"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Indice de pulsatilidade médio acima do percentil esperado para a idade gestacional, aumentando o risco de pré-eclâmpsia e restrição de crescimento precoce."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkIndiceDentro"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Indice de pulsatilidade médio dentro do percentil normal para a idade gestacional, reduzindo o risco de pré-eclâmpsia e restrição de crescimento precoce."
            validate-on-blur
          ></v-checkbox>
          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkPicoAnemLeve"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia leve."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkPicoAnemMod"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia moderada."
            validate-on-blur
          ></v-checkbox>

          <v-checkbox
            v-model="laudoSelecionado.laudoJson.checkPicoGra"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Pico da velocidade sistólica da artéria cerebral média compatível com anemia grave."
            validate-on-blur
          ></v-checkbox>
        </div>

        <!-- <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${camposExibidos.
            exibeFluxoUmbilicalOpcao1 ? '' : 'text--disabled'
          }`"
        >
          <span>Fluxo umbilical</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            :disabled="!camposExibidos.exibeFluxoUmbilicalOpcao1"
            :items="['normal', 'com resistência alterado']"
            v-model="laudoSelecionado.laudoJson.fluxoUmbilicalOpcao1"
            :clearable="podeEditar"
          ></v-select>
          <span>para a idade gestacional, com diástole</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeFluxoUmbilicalOpcao1"
            dense
            validate-on-blur
            :items="['positiva', 'zero', 'reversa']"
            v-model="laudoSelecionado.laudoJson.fluxoUmbilicalOpcao2"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeFluxoUmbilicalOpcao1"
            @click="camposExibidos.exibeFluxoUmbilicalOpcao1 = true;
            laudoSelecionado.laudoJson.fluxoUmbilicalOpcao1 = undefined;
            laudoSelecionado.laudoJson.fluxoUmbilicalOpcao2 = undefined;"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeFluxoUmbilicalOpcao1 = false;
            laudoSelecionado.laudoJson.fluxoUmbilicalOpcao1 = undefined;
            laudoSelecionado.laudoJson.fluxoUmbilicalOpcao2 = undefined;"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>


        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${camposExibidos.
            exibeCentralizacaoFetal ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeCentralizacaoFetal"
            :items="['Ausência', 'Presença']"
            v-model="laudoSelecionado.laudoJson.centralizacaoFetal"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>
          <span>de centralização fetal.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeCentralizacaoFetal"
            @click="camposExibidos.exibeCentralizacaoFetal = true;
            laudoSelecionado.laudoJson.centralizacaoFetal = undefined"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeCentralizacaoFetal = false;
            laudoSelecionado.laudoJson.centralizacaoFetal = undefined"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${camposExibidos.
            exibeRelacaoCerebroPlacentaria ? '' : 'text--disabled'
          }`"
        >
          <span>Relação cérebro-placentária</span>

          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeRelacaoCerebroPlacentaria"
            class="mx-2 flex-grow-0"
            dense
            :items="['normal', 'alterado']"
            v-model.number="laudoSelecionado.laudoJson.relacaoCerebroPlacentaria"
            :clearable="podeEditar"
          ></v-select>
          <span>para idade gestacional.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeRelacaoCerebroPlacentaria"
            @click="camposExibidos.exibeRelacaoCerebroPlacentaria = true;
            laudoSelecionado.laudoJson.relacaoCerebroPlacentaria = undefined"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeRelacaoCerebroPlacentaria = false;
            laudoSelecionado.laudoJson.relacaoCerebroPlacentaria = undefined"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${camposExibidos.
            exibeDuctoVenosoIndice ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeDuctoVenosoIndice"
            class="mx-2 flex-grow-0"
            dense
            :items="[
              'Ducto venoso com Índice de Pulsatilidade',
              '----------------------------------',
            ]"
            v-model="laudoSelecionado.laudoJson.ductoVenosoIndice"
            :clearable="podeEditar"
          ></v-select>

          <v-select
            v-if="
              laudoSelecionado.laudoJson.ductoVenosoIndice ==
              'Ducto venoso com Índice de Pulsatilidade'
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeDuctoVenosoIndice"
            class="mx-2 flex-grow-0"
            dense
            :items="['normal', 'alterado']"
            v-model.number="laudoSelecionado.laudoJson.indiceOpcao5"
            :clearable="podeEditar"
          ></v-select>
          <span
            v-if="
              laudoSelecionado.laudoJson.ductoVenosoIndice ==
              'Ducto venoso com Índice de Pulsatilidade'
            "
            >para idade gestacional.</span
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeDuctoVenosoIndice"
            @click="camposExibidos.exibeDuctoVenosoIndice = true;
            laudoSelecionado.laudoJson.ductoVenosoIndice = undefined;
            laudoSelecionado.laudoJson.indiceOpcao5 = undefined"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeDuctoVenosoIndice = false;
            laudoSelecionado.laudoJson.ductoVenosoIndice = undefined;
            laudoSelecionado.laudoJson.indiceOpcao5 = undefined"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${camposExibidos.
            exibeArteriasUterinas ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeArteriasUterinas"
            dense
            validate-on-blur
            :items="[
              'Artérias uterinas com média do Índice de Pulsatilidade',
              '----------------------------------',
            ]"
            v-model="laudoSelecionado.laudoJson.arteriasUterinas"
            :clearable="podeEditar"
          ></v-select>

          <v-select
            v-if="
              laudoSelecionado.laudoJson.arteriasUterinas ==
              'Artérias uterinas com média do Índice de Pulsatilidade'
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exibeArteriasUterinas"
            dense
            :items="['normal', 'alterado']"
            v-model="laudoSelecionado.laudoJson.arteriasUterinas2"
            :clearable="podeEditar"
          ></v-select>
          <span
            v-if="
              laudoSelecionado.laudoJson.arteriasUterinas ==
              'Artérias uterinas com média do Índice de Pulsatilidade'
            "
            >para idade gestacional.</span
          >
          <v-select
            v-if="
              laudoSelecionado.laudoJson.arteriasUterinas ==
              'Artérias uterinas com média do Índice de Pulsatilidade'
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeArteriasUterinas"
            validate-on-blur
            :items="['reduzindo', 'aumentando']"
            v-model.number="laudoSelecionado.laudoJson.arteriasUterinas3"
            :clearable="podeEditar"
          ></v-select>
          <span
            cols="12"
            class="d-flex justify-start align-center"
            v-if="
              laudoSelecionado.laudoJson.arteriasUterinas ==
              'Artérias uterinas com média do Índice de Pulsatilidade'
            "
            :disabled="!camposExibidos.exibeArteriasUterinas"
            >o risco de restrição de crescimento fetal e pré-eclâmpsia.</span
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeArteriasUterinas"
            @click="camposExibidos.exibeArteriasUterinas = true;
            laudoSelecionado.laudoJson.arteriasUterinas = undefined;
            laudoSelecionado.laudoJson.arteriasUterinas2 = undefined;
            laudoSelecionado.laudoJson.arteriasUterinas3 = undefined"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeArteriasUterinas = false;
            laudoSelecionado.laudoJson.arteriasUterinas = undefined;
            laudoSelecionado.laudoJson.arteriasUterinas2 = undefined;
            laudoSelecionado.laudoJson.arteriasUterinas3 = undefined"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${camposExibidos.
            exibeVelocidadeAcm ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeVelocidadeAcm"
            class="mx-2 flex-grow-0"
            dense
            :items="[
              'Velocidade máxima da ACM normal para a idade gestacional',
              'Velocidade máxima da ACM alterada para a idade gestacional',
              ' ',
            ]"
            v-model="laudoSelecionado.laudoJson.velocidadeAcm"
            :clearable="podeEditar"
          ></v-select>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeVelocidadeAcm"
            @click="camposExibidos.exibeVelocidadeAcm = true;
            laudoSelecionado.laudoJson.velocidadeAcm = undefined"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeVelocidadeAcm = false;
            laudoSelecionado.laudoJson.velocidadeAcm = undefined"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col> -->

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            filled
            outlined
            full-width
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdicionais"
          ></v-textarea>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Perfil Cervical:</h3>
        </v-col>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColoUterino ? '' : 'text--disabled'
          }`"
        >
          <span>Colo uterino</span>
          <v-select
            :clearable="podeEditar"
            required
            :items="['fechado', 'entreaberto', 'aberto']"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloUterino"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino"
          ></v-select>
          <span>, com menor comprimento de </span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloMedidaEmRepouco"
          >
          </v-text-field>
          <span> mm (colo avaliado por três minutos).</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeColoUterino"
            @click="camposExibidos.exibeColoUterino = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeColoUterino = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <!-- <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColoPassouAMedir ? '' : 'text--disabled'
          }`"
        >
          <span>Após três minutos de avaliação, o colo</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            :clearable="podeEditar"
            :items="['passou a medir', 'continuou a medir']"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloPassouAMedir"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoPassouAMedir"
          ></v-select>

          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.cmdois"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoPassouAMedir"
          ></v-text-field>
          <span>cm.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeColoPassouAMedir"
            @click="camposExibidos.exibeColoPassouAMedir = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeColoPassouAMedir = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col> -->

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeOrificioCervicalInterno ? '' : 'text--disabled'
          }`"
        >
          <span>Orifício cervical interno</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="['fechado', 'aberto']"
            required
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.orificioCervicalInterno"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOrificioCervicalInterno"
          ></v-select>

          <span
            v-if="
              laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            "
          >
            com
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.orificioCervicalInterno2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOrificioCervicalInterno"
          ></v-text-field>
          <span
            v-if="
              laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            "
          >
            mm
          </span>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeOrificioCervicalInterno"
            @click="camposExibidos.exibeOrificioCervicalInterno = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeOrificioCervicalInterno = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeProtusaoBolsaAmniotica ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="['Ausência', 'Presença']"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.protusaoBolsaAmniotica"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeProtusaoBolsaAmniotica"
          ></v-select>
          <span>de protrusão da bolsa amniótica no momento do exame.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeProtusaoBolsaAmniotica"
            @click="camposExibidos.exibeProtusaoBolsaAmniotica = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeProtusaoBolsaAmniotica = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSinalDeSludge ? '' : 'text--disabled'
          }`"
        >
          <span>Sinal de sludge</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="['presente', 'ausente']"
            required
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.sinalDeSludge"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSinalDeSludge"
          ></v-select>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeSinalDeSludge"
            @click="camposExibidos.exibeSinalDeSludge = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeSinalDeSludge = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibePolipoEndocervicalMedindo
              ? ''
              : 'text--disabled'
          }`"
        >
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="[
              'Observamos pólipo endocervical medindo',
              '-------------------------------------',
            ]"
            required
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.polipoEndocervicalMedindo"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePolipoEndocervicalMedindo"
          ></v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.polipoEndocervicalMedindo ==
              'Observamos pólipo endocervical medindo'
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.polipoEndocervicalMedindoCm"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePolipoEndocervicalMedindo"
          ></v-text-field>
          <span
            v-if="
              laudoSelecionado.laudoJson.polipoEndocervicalMedindo ==
              'Observamos pólipo endocervical medindo'
            "
          >
            mm
          </span>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibePolipoEndocervicalMedindo"
            @click="camposExibidos.exibePolipoEndocervicalMedindo = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibePolipoEndocervicalMedindo = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColoUterino2 ? '' : 'text--disabled'
          }`"
        >
          <span>Colo uterino</span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            :clearable="podeEditar"
            :items="[
              'de aspecto ecográfico habitual para a idade gestacional',
              'entreaberto, porém, com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
              'entreaberto, com menos de 25 mm de comprimento',
              'fechado, com menos de 25 mm de comprimento',
            ]"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloUterino2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino2"
          ></v-select>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeColoUterino2"
            @click="camposExibidos.exibeColoUterino2 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeColoUterino2 = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="
              laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical
            "
          >
          </v-textarea>
        </v-col>
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          class="ml-5"
          :loading="loadingImprimir"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          v-if="podePersonalizar"
          @click="adcionarConteudoEditorPersonalizado"
          class="ml-5"
        >
          {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      podeEditar: true,
      laudoId: null,
      voltando: false,
      componentKey: 0,
      loadingImprimir: false,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,

      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      camposExibidos: {
        exibeIndicePulsatilidadeArteriaUterinaM: true,
        exibeIndicePulsatilidadeArteriaUmbilical: true,
        exibeDiastole: true,
        exibeIndicePulsatilidadeArteriaCerebralMedia: true,
        exibeVelocidadePicoSistolico: true,
        exibeIndicePulsatilidadeRelacaoCerebroPla: true,
        exibeIndicePulsatilidadeArteriaUterinaD: true,
        exibeIndicePulsatilidadeArteriaUterinaE: true,
        exibeIndicePulsatilidadeArteriaUterinaE2: true,
        exibeDuctoVenoso: true,
        exibeFluxoUmbilicalOpcao1: true,
        exibeCentralizacaoFetal: true,
        exibeRelacaoCerebroPlacentaria: true,
        exibeDuctoVenosoIndice: true,
        exibeArteriasUterinas: true,
        exibeVelocidadeAcm: true,
        checkMamaDireita: false,
        exibeColoUterino: true,
        exibeColoPassouAMedir: true,
        exibeOrificioCervicalInterno: true,
        exibePolipoEndocervicalMedindo: true,
        exibeColoUterino2: true,
        exibeProtusaoBolsaAmniotica: true,
        exibeSinalDeSludge: true,
      },

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 25 mm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    mediaIndice() {
      if (
        !this.laudoSelecionado ||
        !this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD ||
        !this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
      )
        return '';
      let media = (
        (parseFloat(
          this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD
        ) +
          parseFloat(
            this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
          )) /
        2.0
      ).toFixed(2);

      let semanas;
      if (
        !!this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
      ) {
        semanas = parseInt(
          this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
        );
      } else {
        return media;
      }

      if (semanas >= 11 && semanas <= 41) {
        let valores = this.getIMP(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (media - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudoSelecionado.laudoJson.percentilArtMed = resultado;
      } else {
        this.laudoSelecionado.laudoJson.percentilArtMed = '';
      }

      return media;
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let todos = [];

      this.conteudoEditorPersonalizado = [];

      if (this.camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical) {
        todos.push(`\n<h4>Artéria Umbilical</h4>`);
      }

      if (this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical)
        todos.push(
          `Índice de Pulsatilidade: ${
            this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical
          }${
            this.laudoSelecionado.laudoJson.percentil
              ? ' - Percentil ' + this.laudoSelecionado.laudoJson.percentil + ''
              : ''
          }${
            this.laudoSelecionado.laudoJson.indiceOpcao3
              ? ' ' +
                this.laudoSelecionado.laudoJson.indiceOpcao3 +
                ' para idade gestacional'
              : ''
          }.\n`
        );
      if (
        this.camposExibidos.exibeDiastole &&
        this.laudoSelecionado.laudoJson.diastole
      ) {
        todos.push(`Diástole ${this.laudoSelecionado.laudoJson.diastole}\n`);
      }

      if (this.camposExibidos.exibeIndicePulsatilidadeArteriaCerebralMedia) {
        todos.push(`\n<h4>Artéria cerebral média</h4>`);
      }

      if (
        this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaCerebralMedia
      )
        todos.push(
          `Índice de Pulsatilidade: ${
            this.laudoSelecionado.laudoJson
              .indicePulsatilidadeArteriaCerebralMedia
          }${
            this.laudoSelecionado.laudoJson.percentil3
              ? ' - Percentil ' +
                this.laudoSelecionado.laudoJson.percentil3 +
                ''
              : ''
          }${
            this.laudoSelecionado.laudoJson.indiceOpcao1
              ? ' ' +
                this.laudoSelecionado.laudoJson.indiceOpcao1 +
                ' para idade gestacional'
              : ''
          }.\n`
        );
      if (this.laudoSelecionado.laudoJson.opcaoVelocidade)
        todos.push(
          `Velocidade de Pico Sistólica: ${
            this.laudoSelecionado.laudoJson.opcaoVelocidade
          }${
            this.laudoSelecionado.laudoJson.opcaoVelocidade2
              ? ' cm/s, correspondendo a ' +
                this.laudoSelecionado.laudoJson.opcaoVelocidade2 +
                ' múltiplos da mediana'
              : ''
          }.\n`
        );
      if (this.laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla)
        todos.push(
          `Relação cérebro-placentária: ${
            this.laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla
          }${
            this.laudoSelecionado.laudoJson.percentil2
              ? ' - Percentil ' +
                this.laudoSelecionado.laudoJson.percentil2 +
                ''
              : ''
          }${
            this.laudoSelecionado.laudoJson.indiceOpcaoCerePlac
              ? ' ' +
                this.laudoSelecionado.laudoJson.indiceOpcaoCerePlac +
                ' para idade gestacional'
              : ''
          }.\n`
        );

      if (this.camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD) {
        todos.push(`\n<h4>Artéria Uterina</h4>`);
      }

      if (
        this.camposExibidos.exibeIndicePulsatilidadeArteriaUterinaD &&
        this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD
      ) {
        todos.push(
          `Índice de Pulsatilidade (direita): ${this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaD}\n`
        );
      }
      if (
        this.camposExibidos.exibeIndicePulsatilidadeArteriaUterinaE &&
        this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE
      ) {
        todos.push(
          `Índice de Pulsatilidade (esquerda): ${this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUterinaE}\n`
        );
      }

      if (this.mediaIndice)
        todos.push(
          `Índice de Pulsatilidade médio: ${this.mediaIndice}${
            this.laudoSelecionado.laudoJson.percentilArtMed
              ? ' - Percentil ' +
                this.laudoSelecionado.laudoJson.percentilArtMed +
                ''
              : ''
          }${
            this.laudoSelecionado.laudoJson.indiceOpcaoArtMed
              ? ' ' +
                this.laudoSelecionado.laudoJson.indiceOpcaoArtMed +
                ' para idade gestacional'
              : ''
          }.\n`
        );

      // if (
      //   this.camposExibidos.exibeDuctoVenoso &&
      //   this.laudoSelecionado.laudoJson.ductoVenoso
      // ) {
      //   todos.push(`${this.laudoSelecionado.laudoJson.ductoVenoso}\n`);
      //   if (
      //     this.laudoSelecionado.laudoJson.ductoVenoso === 'Ducto Venoso' &&
      //     this.laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso
      //   ) {
      //     todos.push(
      //       ` índice de Pulsatilidade: ${this.laudoSelecionado.laudoJson.indicePulsatilidadeDuctoVenoso} ${this.laudoSelecionado.laudoJson.indiceOpcao4} para idade gestacional.`
      //     );
      //   }
      // }

      if (this.laudoSelecionado.laudoJson.checkFluxoUmb) {
        todos.push(`\n<h4>Conclusão</h4>`);
      }

      if (this.laudoSelecionado.laudoJson.checkFluxoUmb) {
        todos.push(`Fluxo umbilical normal para a idade gestacional.\n`);
      }
      if (this.laudoSelecionado.laudoJson.checkAusFet) {
        todos.push(`Ausência de centralização fetal.\n`);
      }
      if (this.laudoSelecionado.laudoJson.checkRelCerNormal) {
        todos.push(
          `Relação cerebro-placentária normal para a idade gestacional.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkFluxoUmbRes) {
        todos.push(`Fluxo umbilical com resistência elevada.\n`);
      }
      if (this.laudoSelecionado.laudoJson.checkRelCerAcima) {
        todos.push(
          `Relação cerebro-placentária acima do percentil esperado para a idade gestacional. Convém monitorar.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkCentraFet) {
        todos.push(`Centralização fetal.\n`);
      }
      if (this.laudoSelecionado.laudoJson.checkDucto) {
        todos.push(
          `Ducto venoso ${this.laudoSelecionado.laudoJson.ductoVenNormAlt}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkIndiceAcima) {
        todos.push(
          `Indice de pulsatilidade médio acima do percentil esperado para a idade gestacional, aumentando o risco de pré-eclâmpsia e restrição de crescimento precoce.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkIndiceDentro) {
        todos.push(
          `Indice de pulsatilidade médio dentro do percentil normal para a idade gestacional, reduzindo o risco de pré-eclâmpsia e restrição de crescimento precoce.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkPicoAnemLeve) {
        todos.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia leve.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkPicoAnemMod) {
        todos.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia moderada.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.checkPicoGra) {
        todos.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia grave.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdicionais) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdicionais}\n`
        );
      }
      if (this.camposExibidos.exibeIndicePulsatilidadeArteriaUmbilical) {
        todos.push(`\n<h4>Perfil Cervical</h4>`);
      }

      if (
        this.camposExibidos.exibeColoUterino &&
        this.laudoSelecionado.laudoJson.coloUterino
      ) {
        todos.push(
          `Colo uterino ${this.laudoSelecionado.laudoJson.coloUterino}${
            this.laudoSelecionado.laudoJson.coloMedidaEmRepouco
              ? ', com menor comprimento de ' +
                this.laudoSelecionado.laudoJson.coloMedidaEmRepouco +
                ' mm (colo avaliado por três minutos)'
              : ''
          }.\n`
        );
      }
      // if (
      //   this.camposExibidos.exibeColoPassouAMedir &&
      //   this.laudoSelecionado.laudoJson.coloPassouAMedir
      // ) {
      //   todos.push(
      //     `Após três minutos de avaliação, o colo ${
      //       this.laudoSelecionado.laudoJson.coloPassouAMedir
      //     }${
      //       this.laudoSelecionado.laudoJson.cmdois
      //         ? ' ' + this.laudoSelecionado.laudoJson.cmdois
      //         : ''
      //     } cm.\n`
      //   );
      // }

      if (
        this.camposExibidos.exibeOrificioCervicalInterno &&
        this.laudoSelecionado.laudoJson.orificioCervicalInterno
      ) {
        todos.push(
          `Orifício cervical interno ${
            this.laudoSelecionado.laudoJson.orificioCervicalInterno
          }${
            this.laudoSelecionado.laudoJson.orificioCervicalInterno === 'aberto'
              ? ` com ${this.laudoSelecionado.laudoJson.orificioCervicalInterno2} mm`
              : ''
          }.\n`
        );
      }

      if (
        this.camposExibidos.exibeProtusaoBolsaAmniotica &&
        this.laudoSelecionado.laudoJson.protusaoBolsaAmniotica
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.\n`
        );
      }
      if (
        this.camposExibidos.exibeSinalDeSludge &&
        this.laudoSelecionado.laudoJson.sinalDeSludge
      ) {
        todos.push(
          `Sinal de sludge ${this.laudoSelecionado.laudoJson.sinalDeSludge}.\n`
        );
      }

      if (
        this.camposExibidos.exibePolipoEndocervicalMedindo &&
        this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo}${
            this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo ===
            'Observamos pólipo endocervical medindo'
              ? ` ${this.laudoSelecionado.laudoJson.polipoEndocervicalMedindoCm} mm`
              : ''
          }.\n`
        );
      }

      if (this.camposExibidos.exibeColoUterino2) {
        todos.push(`\n<h4>Conclusão</h4>`);
      }

      if (
        this.camposExibidos.exibeColoUterino2 &&
        this.laudoSelecionado.laudoJson.coloUterino2
      ) {
        todos.push(
          `Colo uterino ${this.laudoSelecionado.laudoJson.coloUterino2}.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical}.\n`
        );
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 22);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;
            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo =
        'DOPPLER OBSTÉTRICO COM PERFIL CERVICAL';
    },
    desativaLoading() {
      this.loadingImprimir = false;
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },
    getIndicePulsatilidade() {
      //verificar se o valor tá entre 20 e 42

      let x;
      let semanas;
      if (
        !!this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical
      ) {
        x = this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical;
      } else {
        return;
      }
      if (
        !!this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
      ) {
        semanas = parseInt(
          this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
        );
      } else {
        return;
      }

      if (semanas >= 20 && semanas <= 42) {
        let valores = this.getValoresIndice(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);

        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudoSelecionado.laudoJson.percentil = resultado;
      } else {
        this.laudoSelecionado.laudoJson.percentil = '';
      }

      this.$forceUpdate();
    },
    getValoresIndice(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [1.04, 2.03];
      tabelaIndices[21] = [0.98, 1.96];
      tabelaIndices[22] = [0.92, 1.9];
      tabelaIndices[23] = [0.86, 1.85];
      tabelaIndices[24] = [0.81, 1.79];
      tabelaIndices[25] = [0.76, 1.74];
      tabelaIndices[26] = [0.71, 1.69];
      tabelaIndices[27] = [0.67, 1.65];
      tabelaIndices[28] = [0.63, 1.61];
      tabelaIndices[29] = [0.59, 1.57];
      tabelaIndices[30] = [0.56, 1.54];
      tabelaIndices[31] = [0.53, 1.51];
      tabelaIndices[32] = [0.5, 1.48];
      tabelaIndices[33] = [0.48, 1.46];
      tabelaIndices[34] = [0.46, 1.44];
      tabelaIndices[35] = [0.44, 1.43];
      tabelaIndices[36] = [0.43, 1.42];
      tabelaIndices[37] = [0.42, 1.41];
      tabelaIndices[38] = [0.42, 1.4];
      tabelaIndices[39] = [0.42, 1.4];
      tabelaIndices[40] = [0.42, 1.4];
      tabelaIndices[41] = [0.42, 1.41];
      tabelaIndices[42] = [0.43, 1.42];
      return tabelaIndices[valSemanas];
    },
    getACM() {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      let yRelacao;

      if (
        !!this.laudoSelecionado.laudoJson
          .indicePulsatilidadeArteriaCerebralMedia
      ) {
        x =
          this.laudoSelecionado.laudoJson
            .indicePulsatilidadeArteriaCerebralMedia;
      } else {
        return;
      }
      if (
        !!this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
      ) {
        semanas = parseInt(
          this.laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
        );
      } else {
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical) {
        yRelacao = this.laudoSelecionado.laudoJson.indicePulsatilidadeArteriaUmbilical

        let indiceRCP = x / yRelacao
        this.laudoSelecionado.laudoJson.indicePulsatilidadeRelacaoCerebroPla = indiceRCP.toFixed(2)
        let valores1 = this.getRelacaoCP(semanas);
        let menorvalor1 = valores1[0];
        let maiorvalor1 = valores1[1];
        let percentilRCP =
          5 + ((95 - 5) * (indiceRCP - menorvalor1)) / (maiorvalor1 - menorvalor1);

        percentilRCP = percentilRCP.toFixed(0);
        percentilRCP = percentilRCP > 100 ? " >100 " : percentilRCP
        percentilRCP = percentilRCP < 0 ? ' <1 ' : percentilRCP

        this.laudoSelecionado.laudoJson.percentil2 = percentilRCP


      }

      if (semanas >= 20 && semanas <= 42) {
        let valores = this.getValoresACM(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);

        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudoSelecionado.laudoJson.percentil3 = resultado;
      } else {
        this.laudoSelecionado.laudoJson.percentil3 = '';
      }

      this.$forceUpdate();
    },
    getRelacaoCP(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [4.17, 0.90];
      tabelaIndices[21] = [4.35, 0.91];
      tabelaIndices[22] = [4.55, 0.92];
      tabelaIndices[23] = [4.76, 0.93];
      tabelaIndices[24] = [5.00, 0.93];
      tabelaIndices[25] = [5.00, 0.93];
      tabelaIndices[26] = [5.26, 0.94];
      tabelaIndices[27] = [5.56, 0.95];
      tabelaIndices[28] = [5.88, 0.96];
      tabelaIndices[29] = [6.25, 0.97];
      tabelaIndices[30] = [6.67, 0.98];
      tabelaIndices[31] = [6.67, 0.98];
      tabelaIndices[32] = [7.14, 0.99];
      tabelaIndices[33] = [7.69, 1.00];
      tabelaIndices[34] = [8.33, 1.01];
      tabelaIndices[35] = [9.09, 1.02];
      tabelaIndices[36] = [10.00, 1.03];
      tabelaIndices[37] = [10.00, 1.04];
      tabelaIndices[38] = [11.11, 1.04];
      tabelaIndices[39] = [12.50, 1.05];
      tabelaIndices[40] = [14.29, 1.06];
      tabelaIndices[41] = [16.67, 1.08];
      tabelaIndices[42] = [20.00, 1.09];
      return tabelaIndices[valSemanas];
    },
    getValoresACM(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [1.36, 2.31];
      tabelaIndices[21] = [1.4, 2.34];
      tabelaIndices[22] = [1.44, 2.37];
      tabelaIndices[23] = [1.47, 2.4];
      tabelaIndices[24] = [1.49, 2.42];
      tabelaIndices[25] = [1.51, 2.44];
      tabelaIndices[26] = [1.52, 2.45];
      tabelaIndices[27] = [1.53, 2.45];
      tabelaIndices[28] = [1.53, 2.46];
      tabelaIndices[29] = [1.53, 2.45];
      tabelaIndices[30] = [1.52, 2.44];
      tabelaIndices[31] = [1.51, 2.43];
      tabelaIndices[32] = [1.49, 2.41];
      tabelaIndices[33] = [1.46, 2.39];
      tabelaIndices[34] = [1.43, 2.36];
      tabelaIndices[35] = [1.4, 2.32];
      tabelaIndices[36] = [1.36, 2.28];
      tabelaIndices[37] = [1.32, 2.24];
      tabelaIndices[38] = [1.27, 2.19];
      tabelaIndices[39] = [1.21, 2.14];
      tabelaIndices[40] = [1.15, 2.08];
      tabelaIndices[41] = [1.08, 2.01];
      tabelaIndices[42] = [1.01, 1.94];
      return tabelaIndices[valSemanas];
    },

    getIMP(valSemanas) {
      let tabelaIndices = {};
      tabelaIndices[11] = [1.18, 2.7];
      tabelaIndices[12] = [1.11, 2.53];
      tabelaIndices[13] = [1.05, 2.38];
      tabelaIndices[14] = [0.99, 2.24];
      tabelaIndices[15] = [0.94, 2.11];
      tabelaIndices[16] = [0.89, 1.99];
      tabelaIndices[17] = [0.85, 1.88];
      tabelaIndices[18] = [0.81, 1.79];
      tabelaIndices[19] = [0.78, 1.7];
      tabelaIndices[20] = [0.74, 1.61];
      tabelaIndices[21] = [0.71, 1.54];
      tabelaIndices[22] = [0.69, 1.47];
      tabelaIndices[23] = [0.66, 1.41];
      tabelaIndices[24] = [0.64, 1.35];
      tabelaIndices[25] = [0.62, 1.3];
      tabelaIndices[26] = [0.6, 1.25];
      tabelaIndices[27] = [0.58, 1.21];
      tabelaIndices[28] = [0.56, 1.17];
      tabelaIndices[29] = [0.55, 1.13];
      tabelaIndices[30] = [0.54, 1.1];
      tabelaIndices[31] = [0.52, 1.06];
      tabelaIndices[32] = [0.51, 1.04];
      tabelaIndices[33] = [0.5, 1.01];
      tabelaIndices[34] = [0.5, 0.99];
      tabelaIndices[35] = [0.49, 0.97];
      tabelaIndices[36] = [0.48, 0.95];
      tabelaIndices[37] = [0.48, 0.94];
      tabelaIndices[38] = [0.47, 0.92];
      tabelaIndices[39] = [0.47, 0.91];
      tabelaIndices[40] = [0.47, 0.9];
      tabelaIndices[41] = [0.47, 0.89];

      return tabelaIndices[valSemanas];
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.perfilCervicalComDoppler',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },

    formataLaudo() {
      if (this.exibeObs) {
        this.laudoSelecionado.laudoJson.exibeObs = false;
      } else {
        this.laudoSelecionado.laudoJson.exibeObs = true;
      }
    },

    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },

  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      //   this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
